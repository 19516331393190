
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'180px'"
      >
        <el-form-item label="车牌号码" prop="carNumber">
          <el-input
            v-model="formModel.carNumber"
            placeholder="请输入车牌号码"
            style="width: 180px"
          ></el-input>
        </el-form-item>
        <el-form-item label="授权商户">
          {{ merchantName }}
        </el-form-item>
        <el-form-item label="抵扣卷金额"> {{ reduce }}元 </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确定赠送</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import parkingCouponApi from "@/api/base/parkingCoupon";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";

export default {
  components: {},
  props: ["title", "reduce", "merchantName"],
  data() {
    let Capitalization = (rule, value, callback) => {
      this.formModel[rule.field] = value.toUpperCase();

      callback();
    };
    return {
      myMemberLeaveTime: [
        { required: true, message: "请选择离场时间", trigger: "blur" },
      ],
      formModel: {
        carNumber: "鄂D",
      },
      ruleValidate: {
        carNumber: [
          { required: true, message: "车牌号码不能为空", trigger: "blur" },
          {
            validator: Capitalization,
            trigger: "change",
          },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      typeResult: [],
      companyResult: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
    };
  },
  created() {
    var self = this;
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            return parkingCouponApi.pcGiveDiscountTicket(self.formModel);
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {},
};
</script>