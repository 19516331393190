import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/parkingCoupon/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/parkingCoupon/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/parkingCoupon/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/parkingCoupon/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/parkingCoupon/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}
function remove(id) {
  return request.post(constant.serverUrl + "/base/parkingCoupon/delete/" + id);
}

function pcGiveDiscountTicket(formModel) {
  return request.post(constant.serverUrl + "/base/parkingMerchant/pcGiveDiscountTicket?carNumber="+formModel.carNumber );
}
function parkingMemberDetail() {
  return request.post(constant.serverUrl + "/base/parkingMerchant/parkingMemberDetail/" );
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/parkingCoupon/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function exportXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/base/parkingCoupon/exportXls", formData);
}

export default {
  pageList, create, edit, add, update, remove, batchRemove,exportXls,parkingMemberDetail,pcGiveDiscountTicket
}