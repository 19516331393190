<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">停车场管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/parkingCoupon">停车劵使用明细</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="车牌号码" prop="carNum">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.carNum"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="商户名称"
        v-show="!detail.enableCoupon"
        prop="merchantName"
      >
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.merchantName"
        ></el-input>
      </el-form-item>
      <el-form-item label="使用状态" prop="useStatus">
        <el-select size="mini" v-model="queryModel.useStatus">
          <el-option label="全部" value=""></el-option>
          <el-option label="未使用" value="0"></el-option>
          <el-option label="已使用" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="核销时间" prop="verifyTimeRange">
        <el-date-picker
          v-model="queryModel.verifyTimeRange"
          type="daterange"
          size="mini"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        v-show="detail.enableCoupon"
        >录入车辆</el-button
      >

      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="downloadXls"
        >导出数据</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="carNum"
        label="车牌号码"
        width="130"
      ></el-table-column>

      <el-table-column
        v-if="!detail.enableCoupon"
        prop="merchantName"
        label="登记商户"
        width="150"
      ></el-table-column>
      <!-- 
      <el-table-column prop="useStatus" label="使用状态" width="90">
        <template slot-scope="{ row }">
          <span v-if="row.useStatus">使用</span>
          <span v-else>未使用</span>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="reduce"
        label="折扣卷金额(元)"
        width="130"
      ></el-table-column>
      <el-table-column
        prop="inParkingTime"
        label="入场时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="outParkingTime"
        label="出场时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="发放时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="verifyTime"
        label="核销时间"
        width="180"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>

    <parkingCoupon-detail
      v-if="showModal"
      :merchantName="merchantName"
      :reduce="reduce"
      @close="onDetailModalClose"
    ></parkingCoupon-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import parkingCouponApi from "@/api/base/parkingCoupon";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import ParkingCouponDetail from "./parkingCoupon-detail";

import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "baseParkingCouponList",
  data() {
    var self = this;

    return {
      detail: {},
      queryModel: {
        id: "",
        merchantName: "",
        type: "",
        companyId: "",
        verifyTimeRange: ["", ""],
        carNum: "",
        useStatus: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      typeResult: [],
      companyResult: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      downloadLoading: false,
    };
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });
  },
  methods: {
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    init() {
      var self = this;

      //self.loading = true;

      parkingCouponApi
        .parkingMemberDetail()
        .then(function (response) {
          //self.loading = false;

          self.detail = response.data.data;
        })
        .catch((error) => {
          // self.loading = false;
          // self.$message.error(error + "");
        });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("merchantName", self.queryModel.merchantName);
      formData.append("type", self.queryModel.type);

      if (self.queryModel.companyId != null) {
        formData.append("companyId", self.queryModel.companyId);
      }
      formData.append("carNum", self.queryModel.carNum);
      formData.append("verifyTime", self.queryModel.verifyTime);

      if (self.queryModel.verifyTimeRange[0] != null) {
        formData.append("verifyStartTime", self.queryModel.verifyTimeRange[0]);
      }

      if (self.queryModel.verifyTimeRange[1] != null) {
        formData.append("verifyEndTime", self.queryModel.verifyTimeRange[1]);
      }

      formData.append("useStatus", self.queryModel.useStatus);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      parkingCouponApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "录入车辆";
      this.merchantName = this.detail.merchantName;
      this.reduce = this.detail.reduce;
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          parkingCouponApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        parkingCouponApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    downloadXls() {
      //导出
      var self = this;
      self.downloadLoading = true;
      self.loading = true;
      self.loadingText = "数据导出中，请稍后...";

      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("merchantName", self.queryModel.merchantName);
      formData.append("type", self.queryModel.type);

      if (self.queryModel.companyId != null) {
        formData.append("companyId", self.queryModel.companyId);
      }
      formData.append("carNum", self.queryModel.carNum);
      formData.append("verifyTime", self.queryModel.verifyTime);

      if (self.queryModel.verifyTimeRange[0] != null) {
        formData.append("verifyStartTime", self.queryModel.verifyTimeRange[0]);
      }

      if (self.queryModel.verifyTimeRange[1] != null) {
        formData.append("verifyEndTime", self.queryModel.verifyTimeRange[1]);
      }

      formData.append("useStatus", self.queryModel.useStatus);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      parkingCouponApi.exportXls(formData).then(function (response) {
        var jsonData = response.data;
        self.downloadLoading = false;
        self.loading = false;
        if (jsonData.result) {
          //下载有错误信息提示的报表
          self.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `报表已生成，<a href="${jsonData.data}" target="_blank">点击下载报表</a>&nbsp;`,
            duration: 60000,
            type: "success",
          });
        } else {
          self.$message.error(jsonData.message);
        }
      });
    },
  },
  mounted: function () {
    this.init();
    this.changePage(1);
  },
  components: {
    "parkingCoupon-detail": ParkingCouponDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>